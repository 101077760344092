<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >
          <!-- Resource Group tab -->
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                class="mb-2"
                cols="12"
              />
              <b-col md="12">
                <b-row
                  v-for="(Gradingweightage, index) in Gradingweightages"
                  :key="index"
                  class="mb-2"
                >
                  <b-col>
                    <b-form-group
                      label="Trench"
                      label-for="Trench"
                    >
                      <v-select
                        v-model="trench"
                        :options="trench"
                        :reduce="trench => trench.id"
                        label=" "
                        placeholder="Choose Trench"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="Grading weightage"
                      label-for="Grading weightage"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Gradingweightage"
                        rules=""
                      >
                        <b-form-input
                          id="Gradingweightage"
                          v-model="Gradingweightage.number"
                          type="float"
                          placeholder="Grading weightage"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FormWizard } from 'vue-form-wizard'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },

  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      trench: [],
      Gradingweightages: [
        {
          number: '',
        },
      ],
    }
  },
  // props: ['inheritedSections'],
  methods: {
    notifyParent() {
      this.$emit('input', this.sections)
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },

}
</script>

<style lang="scss">
.vs__dropdown-menu{
  z-index: 1051 !important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
